 
import Home from "../Home/Home";
 
import Main from "../Main/Main";
 
 

const { createBrowserRouter } = require("react-router-dom");

const Router=createBrowserRouter([
    {path:"/",element:<Main/>,children:[
        {path:"/",element:<Home/>},
        {path:"/home",element:<Home/>},
       
     
       

    ]},
   

])

export default Router;