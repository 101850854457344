import React from 'react';
import './Home.css'
import '../../App.css'
import { Helmet } from 'react-helmet';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Aos from 'aos';
Aos.init();


const Home = () => {
   
    return (
        
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Home</title>

            </Helmet>
            <>
  {/* Gbpoietin Frist part start------------------------------------------- */}
  {/* cover page start-------------------------------------------------- */}
  <section>
    <img
      className="img-fluid W-100"
      src="https://i.ibb.co/3sHqVRr/coverpage.png"
      alt=""
    />
  </section>
  {/* cover page End-------------------------------------------------- */}
  <section className="container-fluid mt-lg-5">
    <div>
      <div
        className="card w-100"
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration={1500}
      >
        <div className="card-body">
          <div className="row  align-items-center">
            <div className="col-lg-4 col-12 d-none d-lg-block">
              {" "}
              <img
                src="https://i.ibb.co/fYgpFjS/injection.png"
                className="img-fluid  h-100 w-100 mx-auto"
                alt="..."
              />
            </div>
            <div className="col-lg-4 col-12">
              <div className="w-100 w-md-25 h-md-25 h-100 d-flex justify-content-center mx-auto">
                <img
                  className="img-fluid ms-2 ms-lg-0"
                  src="https://i.ibb.co/Fgh1FMq/logo.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-12 mt-2 d-block d-md-block d-lg-none ">
              {" "}
              <img
                src="https://i.ibb.co/fYgpFjS/injection.png"
                className="h-50 w-50 mx-auto d-flex  justify-content-center"
                alt="..."
              />
            </div>
            <div className="col-lg-4 col-12 ">
              <div className="top-RigtSide text-center px-2 mt-4 md-mt-6">
              <span>Human Serum Albumin and Fetal Bovine Serum Free</span>
              </div>
              <p className="top-rightSidebarPra text-center mt-2 md-mt-6">
                No risk of cross-infection by opportunistic biological agent
                from components
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Gbpoietin Frist part End-------------------------------------------- */}
  {/* Second Section start--------------------------------------- */}
  <section className="container-fluid">
    <div className="card w-100">
      <div className="card-body">
        <div className="row">
          <div className="col-lg-4 col-12 col-md-12 d-lg-none d-block">
            <div
              className="d-flex justify-content-center align-items-center"
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration={1500}
            >
              <img
                className="img-fluid d-none d-lg-block"
                src="https://i.ibb.co/Xjf8z5p/sample.png"
                alt=""
              />
              <img
                className="img-fluid w-25 d-lg-none h-25"
                src="https://i.ibb.co/Xjf8z5p/sample.png"
                alt=""
              />
              <div className="forth-part">
                <span>
                  {" "}
                  <b style={{ color: "#1a67b2" }}>GB</b>
                  <i>
                    <b>poietin®</b>
                  </i>{" "}
                  is a <br />
                  <span> recombinant erythropoietin,</span> <br />
                  <span>which promotes red blood </span>
                  <br />
                  <span> cell production and thereby</span> <br />
                  <span>enhance oxygen-carrying</span>
                  <br />
                  <span> capacity of blood and </span>
                  <br />
                  <span>ultimately the vitality of</span>
                  <br />
                  <span> human. The picture shows a</span>
                  <br />
                  <span>3D model of this peptide.</span>
                </span>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-12 part-five"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration={1500}
          >
            <button
              type="button"
              className="btn btn-outline-primary Presenting"
            >
              <i>Presenting</i>
            </button>
            <img
              className="img-fluid"
              src="https://i.ibb.co/8jvQdNQ/Untitled-1.png"
              alt=""
            />
            <p>
              {" "}
              <b style={{ color: "#1a67b2" }}>GB</b>
              <i>
                <b>poietin®</b>
              </i>{" "}
              is delivered in single-use biopure pre-filled syringe (PFS). For
              convenience of the patient, sterilizing pad and bandage is
              provided in secured blister pack. The preferred application mode
              is subcutaneous injection at a suitable place of body, like thigh
              or arm or belly etc.
              <br /> <br /> <b style={{ color: "#1a67b2" }}>GB</b>
              <i>
                <b>poietin®</b>
              </i>{" "}
              is available in four different strengths shown in the picture.{" "}
              <b style={{ color: "#1a67b2" }}>GB</b>
              <i>
                <b>poietin®</b>
              </i>{" "}
              (2000 IU/0.5mL, 4000 IU/mL, 5000 IU/0.5 mL, 10000 IU/mL)
            </p>
            {/* responsive product card------------------------------------------- */}
            <div></div>
            {/* responsive product card------------------------------------------- */}
          </div>
          <div
            className="col-lg-4 col-12 col-md-12 d-none d-lg-block "
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration={1500}
          >
            <div className="">
              <img
                className="img-fluid d-none d-lg-block w-75 h-75 mx-auto"
                src="https://i.ibb.co/Xjf8z5p/sample.png"
                alt=""
              />
              <img
                className="img-fluid w-25 d-lg-none h-25"
                src="https://i.ibb.co/Xjf8z5p/sample.png"
                alt=""
              />
              <div className="forth-partlg">
                <p>
                  {" "}
                  <b style={{ color: "#1a67b2" }}>GB</b>
                  <i>
                    <b>poietin®</b>
                  </i>{" "}
                  is a recombinant erythropoietin, which promotes red blood cell
                  production and thereby enhance oxygen-carrying capacity of
                  blood and ultimately the vitality of human. The picture shows
                  a 3D model of this peptide.
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-12 fivelast-col"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration={1500}
          >
            <button
              type="button"
              className="btn btn-outline-primary Presenting"
            >
              <i>Mode of action</i>
            </button>
            <div>
              <img
                className="img-fluid"
                src="https://i.ibb.co/gtwVFfw/mode-of-action.png"
                alt=""
              />
              <p>
                In response of low blood oxygen, kidney and liver secrete
                erythropoietin (EPO) that promotes erythropoiesis and RBC
                production in bone marrow. In disease condition, natural EPO
                production is compromised;{" "}
                <b style={{ color: "#1a67b2" }}>GB</b>
                <i>
                  <b>poietin®</b>
                </i>{" "}
                effectively and safely restores normal oxygen level in blood.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Second Section End--------------------------------------- */}
  {/* thrird Section------------------------------- */}
  <section className="container-fluid six-col ">
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div
            className="col-lg-4 col-12"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration={1500}
          >
            <button
              type="button"
              className="btn btn-outline-primary Presenting"
            >
              <i>Indications</i>
            </button>
            <div className="card w-100">
              <div className="card-body">
                <img
                  className="img-fluid"
                  src="https://i.ibb.co/QQbYcN9/indications.png"
                  alt=""
                />
                <p id="indication">
                  Specific indications depend on physician’s evaluation
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration={1500}
          >
            <button
              type="button"
              className="btn btn-outline-primary Presenting"
            >
              <i>Recommended doses</i>
            </button>
            <table id="table" className="table table-bordered mt-4">
              <thead>
                <tr>
                  <th scope="col">Indication</th>
                  <th scope="col">Dose</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Chronic renal failure</td>
                  <td>
                    50-100 IU/kg, 3 times/week, Intravenous (IV)/Subcutaneous
                    (SC)
                  </td>
                </tr>
                <tr>
                  <td>Adult pre-dialysis patients </td>
                  <td>50-100 IU/kg, 3 times/week, IV/SC</td>
                </tr>
                <tr>
                  <td> Adult hemodialysis patients </td>
                  <td>50-100 IU/kg, 3 times/week, IV/SC</td>
                </tr>
                <tr>
                  <td>Adult peritoneal dialysis patients </td>
                  <td>50-100 IU/kg, 2 times/week, IV/SC</td>
                </tr>
                <tr>
                  <td> Pediatric hemodialysis patients </td>
                  <td> 50 IU/kg, 2 times/week, IV</td>
                </tr>
                <tr>
                  <td> Cancer patients </td>
                  <td> 150 IU/kg, 3 times/week, SC</td>
                </tr>
                <tr>
                  <td> HIV infected patients on zidovudine </td>
                  <td> 100 IU/kg, 3 times/week for 8 weeks, SC/IV</td>
                </tr>
                <tr>
                  <td>
                    {" "}
                    Adult surgery patients in an autologous pre-donation program{" "}
                  </td>
                  <td>
                    600 IU/kg, 2 times/week IV for 3 weeks prior to surgery{" "}
                  </td>
                </tr>
                <tr>
                  <td>Surgery patients without autologous blood donation</td>
                  <td>
                    600 IU/kg once a week SC for 3 weeks prior to surgery or 300
                    IU/kg daily for 10 days before surgery and repeat on day of
                    surgery, continue for 4 days after surgery{" "}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            className="col-lg-4"
            id="grap-part"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration={1500}
          >
            <button
              type="button"
              className="btn btn-outline-primary Presenting mt-3 mt-lg-0 mb-3"
            >
              <i>Clinical trial results*</i>
            </button>
            <p>
              <b style={{ color: "#1a67b2" }}>GB</b>
              <i>
                <b>poietin® </b>
              </i>{" "}
              showed similar pharmacokinetic (a) and pharmacodynamic (b-e)
              responses with the reference drug in human.
            </p>
            <div id="grap">
              <img
                className="img-fluid d-flex justify-content-center "
                src="https://i.ibb.co/kHj7kCz/graph1.png"
                alt=""
              />
              <img
                className="img-fluid d-flex justify-content-center mt-4"
                src="https://i.ibb.co/r3FwGTM/graph2.png"
                alt=""
              />
              <img
                className="img-fluid d-flex justify-content-center mt-4"
                src="https://i.ibb.co/sqhnTmJ/graph3.png"
                alt=""
              />
              <img
                className="img-fluid d-flex justify-content-center mt-4"
                src="https://i.ibb.co/rHSQXC7/graph4.png"
                alt=""
              />
              <img
                className="img-fluid d-flex justify-content-center mt-4"
                src="https://i.ibb.co/yFzX4cR/graph5-1.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* thrird SectionEnd------------------------------- */}
  {/* Four Section Start---------------------------------------- */}
  <section className="container-fluid four-part">
    <div
      className="card"
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration={1500}
    >
      <button type="button" className="btn btn-outline-primary Presenting">
        <i>Safety*</i>
      </button>
      <div className="card-body">
        <div className="row align-items-center flex-column-reverse  flex-lg-row">
          <div className="col-lg-6 col-12 col-md-12">
            <div>
              <img
                className="img-fluid"
                src="https://i.ibb.co/LnMQ57j/safetynew.png"
                alt=""
              />
            </div>
          </div>
          <div className="col-lg-6 col-12 col-md-12 ">
            <p id="md-font">
              {" "}
              <b style={{ color: "#1a67b2" }}>GB</b>
              <i>
                <b>poietin®</b>
              </i>{" "}
              showed better tolerance compared with the reference product in
              local population.{" "}
            </p>
          </div>
        </div>
        <p id="md-font" className="mt-3">
          Total number of reported minor adverse events in clinical trial. No
          serious adverse event was reported.
        </p>
        <p id="md-font" style={{ width: "100%" }}>
          <b>*Ref:</b> Clinical evaluation in adult human revealed the
          biosimilarity of recombinant Erythropoietin GBPD002 with Eprex®.
          Mamun-Al-Mahtab, Sitesh C. Bachar et.al.; Arch Clin Biomed Res 2023;
          DOI:10.26502/acbr.50170361.
        </p>
      </div>
    </div>
  </section>
  {/* Four Section End---------------------------------------- */}
  {/* Second page part Start----------------------------------------------------------------- */}
  <section
    className="total-part"
    data-aos="fade-down"
    data-aos-easing="linear"
    data-aos-duration={1500}
  >
    {/* Section One start------------------------------- */}
    <section
      className="container-fluid"
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration={1500}
    >
      <div className="card section-one">
        <div className="card-body">
          <div className="heading-text">
            <h1 className="text-center ">
              The First Biosimilar in Bangladesh Developed by DNA-Cell
              Engineering
            </h1>
          </div>
        </div>
      </div>
    </section>
    {/* Section One End--------------------------------------------- */}
    {/* Section Two start------------------------------- */}
    <section
      className="container-fluid"
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration={1500}
    >
      <div className="card" style={{ border: "none" }}>
        <div className="card-body two-part">
          <div className="section-two">
            <h1 className="text-center">Single-Use Technology</h1>
          </div>
          <span id="single-text">
            <b style={{ color: "#1a67b2" }}>GB</b>
            <i>
              <b>poietin®</b>
            </i>{" "}
            is manufactured using qualified 'Cell Bank', fully validated active
            pharmaceutical ingredient (API) and 'Fill-finish' manufacturing
            process. Rigorous 'Quality management' ensures consistent product
            quality.
          </span>
        </div>
      </div>
    </section>
    {/* Section Two End--------------------------------------------- */}
    {/* Section three start------------------------------- */}
    <section className="container-fluid">
      <div className="card" style={{ border: "none" }}>
        <div className="card-body  three-part">
          <div
            className="three-section"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration={1500}
          >
            <h1 className="text-center">
              Process flow of <b style={{ color: "#1a67b2" }}>GB</b>
              <i>
                <b>poietin®</b>
              </i>{" "}
            </h1>
          </div>
          <div
            className="d-block d-md-none d-lg-block "
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration={1500}
          >
            <div className="allimg-part d-flex flex-column flex-lg-row align-items-lg-center justify-content-lg-evenly mt-5">
              <img
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration={1500}
                className="img-fluid "
                src="https://i.ibb.co/ZVTHtwb/processflow-1.png"
                alt=""
              />
              <img
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration={1500}
                className="img-fluid mt-5 mt-lg-0 "
                src="https://i.ibb.co/LCyrR1s/processflow-2.png"
                alt=""
              />
              <img
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration={1500}
                className="img-fluid mt-5 mt-lg-0 "
                src="https://i.ibb.co/nryyyBY/processflow3.png"
                alt=""
              />
              <img
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration={1500}
                className="img-fluid  mt-5 mt-lg-0"
                src="https://i.ibb.co/GFG6SmC/processflow4.png"
                alt=""
              />
              <img
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration={1500}
                className="img-fluid mt-5 mt-lg-0 "
                src="https://i.ibb.co/yYLcmgQ/processflow5.png"
                alt=""
              />
              <img
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration={1500}
                className="img-fluid mt-5  mt-lg-0"
                src="https://i.ibb.co/cryT4ch/processflow6.png"
                alt=""
              />
            </div>
            <h3 id="human-Epo" className="text-center mt-2">
              Human EPO-expressing CHO-hybrid clonal cell bank development
            </h3>
          </div>
          <div
            className="allimg-part  d-none d-lg-none d-md-block mt-5"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration={1500}
          >
            <img
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration={1500}
              className="img-fluid "
              src="https://i.ibb.co/ZVTHtwb/processflow-1.png"
              alt=""
            />
            <img
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration={1500}
              className="img-fluid mt-5 mt-lg-0 "
              src="https://i.ibb.co/LCyrR1s/processflow-2.png"
              alt=""
            />
            <img
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration={1500}
              className="img-fluid mt-5 mt-lg-0 "
              src="https://i.ibb.co/nryyyBY/processflow3.png"
              alt=""
            />
            <img
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration={1500}
              className="img-fluid  mt-5 mt-lg-0"
              src="https://i.ibb.co/GFG6SmC/processflow4.png"
              alt=""
            />
            <img
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration={1500}
              className="img-fluid mt-5 mt-lg-0 "
              src="https://i.ibb.co/yYLcmgQ/processflow5.png"
              alt=""
            />
            <img
              data-aos="fade-down"
              data-aos-easing="linear"
              data-aos-duration={1500}
              className="img-fluid mt-5  mt-lg-0"
              src="https://i.ibb.co/cryT4ch/processflow6.png"
              alt=""
            />
          </div>
          <h3
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration={1500}
            className="text-center mt-2 d-none d-lg-none d-md-block"
          >
            Human EPO-expressing CHO-hybrid clonal cell bank development
          </h3>
        </div>
      </div>
      {/* flow2---------------------------------- */}
      <div
        className="card w-100"
        style={{ border: "none" }}
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration={1500}
      >
        <div className="card-body flow-two">
          <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center">
            <img
              className="img-fluid  d-none d-md-none d-lg-block"
              src="https://i.ibb.co/h16vCY0/Untitled-1.png"
              alt=""
            />
            <img
              className="img-fluid img-nine d-block d-md-block d-lg-none"
              src="https://i.ibb.co/WPnZj0N/processflow9-2.png"
              alt=""
            />
            <img
              className="img-fluid img-eight d-block d-md-block d-lg-none"
              src="https://i.ibb.co/NrJh2Dn/processflow-8.png"
              alt=""
            />
          </div>
        </div>
      </div>
      {/* flow-2 End------------------------------- */}
      {/* flow-3 start-------------------------------------------------- */}
      <div
        className="card mt-5  "
        style={{ border: "none" }}
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration={1500}
      >
        <div className="card-body flow-three">
          <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center ">
            <img
              className="img-fluid d-none d-lg-block  "
              src="https://i.ibb.co/TYRmttP/Untitled-1.png"
              alt=""
            />
          </div>
          <p className="text-center d-none d-lg-block">
            Drug formulation and dose preparation ensured by thorough
            characterization
          </p>
        </div>
        {/* ----------- */}
        <div
          className="card-body flow-three d-block d-lg-none"
          style={{
            background:
              "radial-gradient(circle, rgba(214,234,202,1) 0%, rgba(66,164,70,1) 100%)",
            borderRadius: 10
          }}
        >
          <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center">
            <img
              className="img-fluid d-block d-lg-none "
              src="https://i.ibb.co/2MgkFnw/processflow-10.png"
              alt=""
            />
            <img
              className="img-fluid d-none d-lg-block "
              src="https://i.ibb.co/TYRmttP/Untitled-1.png"
              alt=""
            />
            <img
              className="img-fluid processflow-11 d-block d-lg-none"
              src="https://i.ibb.co/dDFyrjK/processflowresponsive.png"
              alt=""
            />
            <img
              className="img-fluid d-block d-lg-none  mt-3"
              src="https://i.ibb.co/3Ft68W5/processflow-Rabbit.png"
              alt=""
            />
            <img
              className="img-fluid d-block d-lg-none  mt-3"
              src="https://i.ibb.co/7tTfkJY/Processflowlogo.png"
              alt=""
            />
          </div>
          <p className="text-center" style={{ fontWeight: "bold" }}>
            Drug formulation and dose preparation ensured by thorough
            characterization
          </p>
        </div>
        {/*  */}
      </div>
      {/* flow-3 ENd-------------------------------------------------- */}
    </section>
    {/* Section three End--------------------------------------------- */}
    <div
      className="container-fluid processflow-last"
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration={1500}
    >
      <div className="section-Qpp">
        <h1 id="Qpd" className="text-center">
          <span className="text-center text-white">
            {" "}
            <b style={{ color: "#FFFFFF" }}>GB</b>
            <i>
              <b>poietin®</b>
            </i>{" "}
            is developed through 'Quality by Design' (QbD) to ensure consistent
            'Quality Target Product Profile' (QTPP)
          </span>
        </h1>
      </div>
    </div>
    {/* flow4 start------------------------------ */}
    <div
      className="card mt-3"
      style={{ border: "none" }}
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration={1500}
    >
      <div className="card-body d-flex justify-content-center">
        <div className="row">
          <div className="col-12 col-lg-5 mt-4">
            <div className="card" style={{ border: "none" }}>
              <div
                className="card-body pharmaceutics "
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration={1500}
              >
                <div className=" d-none d-md-block d-lg-block">
                  <img
                    className="img-fluid"
                    src="https://i.ibb.co/nfMX1nS/pharmacelogo.png"
                    alt=""
                  />
                  <span className="ms-2">
                    <i>pharmaceutics</i>
                  </span>
                  <small>Pharmaceutics2023,Volume 15,Issue 8,2087</small>
                  <img
                    className="img-fluid qr-img"
                    src="https://i.ibb.co/fMVNmPQ/pharmace-Qr.png"
                    alt=""
                  />
                  <p>an Open Access Journal MDPI</p>
                  <span style={{ color: "#076867" }}>
                    Satisfying QTPP of Erythropoietin Biosimilar by QbD through
                    DoE-Derived Downstream Process Engineering
                  </span>
                </div>
                {/* mobile view---------------- */}
                <div
                  className="  align-items-center d-block d-md-none d-lg-none "
                  data-aos="fade-down"
                  data-aos-easing="linear"
                  data-aos-duration={1500}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <img
                        data-aos="fade-down"
                        data-aos-easing="linear"
                        data-aos-duration={1500}
                        className="img-fluid"
                        src="https://i.ibb.co/nfMX1nS/pharmacelogo.png"
                        alt=""
                      />
                      <span className="ms-2">
                        <i>pharmaceutics</i>
                      </span>
                    </div>
                    <img
                      data-aos="fade-down"
                      data-aos-easing="linear"
                      data-aos-duration={1500}
                      className="img-fluid qr-img"
                      src="https://i.ibb.co/fMVNmPQ/pharmace-Qr.png"
                      alt=""
                    />
                  </div>
                  <div>
                    <small>Pharmaceutics2023,Volume 15,Issue 8,2087</small>
                    <p>an Open Access Journal MDPI</p>
                    <span style={{ color: "#076867" }}>
                      Satisfying QTPP of Erythropoietin Biosimilar by QbD
                      through DoE-Derived Downstream Process Engineering
                    </span>
                  </div>
                </div>
                {/* mobile view---------------- */}
              </div>
            </div>
          </div>
          <div
            className="col-12 col-lg-7"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration={1500}
          >
            <img
              id="graph-left"
              className="img-fluid d-none d-lg-block"
              style={{ height: 400 }}
              src="https://i.ibb.co/XWWGdjB/processflowgraphpng.png"
              alt=""
            />
            <img
              className="img-fluid d-block d-lg-none d-md-block mt-4"
              src="https://i.ibb.co/XWWGdjB/processflowgraphpng.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    {/* flow4 End------------------------------ */}
    {/* Section flow5 start------------------------------------- */}
    <section
      data-aos="fade-down"
      data-aos-easing="linear"
      data-aos-duration={1500}
      className="container-fluid mt-4 "
    >
      <div className="card" style={{ border: "none" }}>
        <div className="Comparative-value">
          <h1 className="text-center">
            {" "}
            <span>
              Comparative value of <b style={{ color: "#FFFFFF" }}>GB</b>
              <i>
                <b>poietin®</b>
              </i>{" "}
            </span>
          </h1>
        </div>
        <div className="card-body Comparative-part mt-5 ">
          <div>
            <div className="d-flex">
              <img
                className="img-fluid"
                src="https://i.ibb.co/5h7n2yd/vulet.png"
                alt=""
              />
              <div>
                <span id="bullet-point">
                  Clinically proven safe and effective in local population
                </span>
              </div>
            </div>
            <div className=" mt-3 d-flex ">
              <img
                className="img-fluid"
                src="https://i.ibb.co/5h7n2yd/vulet.png"
                alt=""
              />
              <div>
                <span id="bullet-point">
                  Locally developed for satisfying global quality at afforadable
                  price
                </span>
              </div>
            </div>
            <div className="mt-3 d-flex">
              <img
                className="img-fluid"
                src="https://i.ibb.co/5h7n2yd/vulet.png"
                alt=""
              />
              <div>
                <span id="bullet-single">
                  'Single-Use Technology' reduces chance of relevant
                  cross-contamination to 'zero'
                </span>
              </div>
            </div>
            <div className="mt-3 d-flex">
              <img
                className="img-fluid"
                src="https://i.ibb.co/5h7n2yd/vulet.png"
                alt=""
              />
              <div>
                <span id="bullet-single">
                  {" "}
                  'Biopure' 'Ready-To-Use' syringe ensures quality product from
                  manufacturing to use
                </span>
              </div>
            </div>
            <div className="mt-3 d-flex">
              <img
                className="img-fluid"
                src="https://i.ibb.co/5h7n2yd/vulet.png"
                alt=""
              />
              <div>
                <span id="bullet-single">
                  {" "}
                  No animal origin material ensures absence of biological
                  contaminants from components
                </span>
              </div>
            </div>
            <div className="mt-3 d-flex">
              <img
                className="img-fluid"
                src="https://i.ibb.co/5h7n2yd/vulet.png"
                alt=""
              />
              <div>
                <span id="bullet-singleNO">
                  {" "}
                  No risk associated with the product quality due to
                  transportation challenge from abroad
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Section flow5 End------------------------------------- */}
  </section>
  {/* Second page part End----------------------------------------------------------------- */}
  {/* Section flow6 start-------------------------------- */}
  <section className="container-fluid mb-5">
    <div className="card six-card " style={{ border: "none" }}>
      <div id="section-six">
        <hr id="hr" />
        <div className="row align-items-center  ">
          <div className="col-12 col-lg-8">
            <div className="d-flex align-items-center justify-content-evenly justify-content-lg-start flex-lg-row  ">
              <img
                className="img-fluid "
                src="https://i.ibb.co/NpxSsYR/footerlogo.png"
                alt=""
              />
              <div id="footerpart">
                <h3 className="ms-3">
                  <span id="globe-biotech">Globe Biotech Limited</span> <br />{" "}
                  <span id="dhaka">Dhaka, Bangladesh</span>
                </h3>
                <span id="contact" className="d-lg-none d-md-block d-block">
                  Contact/Complain:+8809609777700 <br />
                  Web :www.globe-biotech.com/contact <br />
                  WhatsApp : 01313 754 505-07
                </span>
               
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 d-none d-lg-block d-md-none  d-flex justify-content-end">
            <span style={{ fontSize: 20 }}>
              Contact/Complain:+8809609777700 <br />
              Web :www.globe-biotech.com/contact <br />
              WhatsApp : 01313 754 505-07
            </span>
          
         
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* Section flow6 End-------------------------------- */}
</>


        </div>
    );
};

export default Home;