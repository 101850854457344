import React from 'react'; 
import { Outlet } from 'react-router-dom';
 
 

const Main = () => {
    return (
        <div>
            
            <Outlet/>
            {/* <div className="card mt-4 lg:hidden">
                    <div className="card-body" data-aos="fade-down"
                                    data-aos-easing="linear"
                                    data-aos-duration="1500">
                        <hr className='border-4 text-[#C7C8C9]' />
                        <div className='flex items-center justify-between mt-4 '>
                            <img className='h-[50%] w-[34%]' src="https://i.ibb.co/NpxSsYR/footerlogo.png" alt="" />
                            <div>
                                <h1 className='text-[18px] md:text-[38px] font-bold'>Globe Biotech Limited</h1>
                                <h3 className=' text-[15px]  text-center md:text-[32px] font-semibold'>Dhaka, Bangladesh</h3>
                                <h1 className=' text-[10px] md:text-[23px] text-center  '>Contact/Complain:+8809609777700
                                </h1>
                                <h1 className=' text-[10px] text-center md:text-[23px]  '>Web:www.globe-biotech.com/contact</h1>
                                <h1 className=' text-[10px] text-center md:text-[23px]  '>WhatsApp : 01313 754 505-07</h1>
                                <span className='flex justify-center items-center'> <Link className='mr-1' to="/login">Login</Link >  |  <Link onClick={()=>handleLocate()} className='ml-1' to="/home">Home</Link> </span>

                            </div>
                        </div>
                    </div>
                </div> */}
          
        </div>
    );
};

export default Main;