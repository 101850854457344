  import { RouterProvider } from 'react-router-dom';
import Router from './Components/Router/Rouoter';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';


function App() {
  return (
    <div>
       <RouterProvider router={Router}></RouterProvider>
       <ToastContainer position="top-center" />
    </div>
  );
}

export default App;
